import { IWebsiteConfig, IMainReduxState } from "core/interfaces.config";

export const DISPLAY_ERROR = "display-error";
export const DISPLAY_NOTIFICATION = "display-notification";
export const SET_CONFIGURATION = "set-configuration";
export const NO_CONFIGURATION = "no-configuration";
export const TOGGLE_MOBILE_MENU = "toggle-mobileMenu";
export const SET_INSTANCE = "set-instance";
export const SET_LICENCE_SEASON = "set-licence-season";
export const SET_CURRENT_PERSONID = "set-current-personId";
export const SET_CONSENTS = "set-consents";

const initialState: IMainReduxState = {
  error: null,
  notification: null,
  websiteConfiguration: {} as IWebsiteConfig,
  noConfiguration: undefined,
  mobileMenuIsOpen: false,
  instance: undefined,
  registrationKey: "",

  currentPersonId: undefined,

  consents: [],
};

const mainReducer = (
  state: IMainReduxState = initialState,
  action: any
): IMainReduxState => {
  switch (action.type) {
    case DISPLAY_ERROR:
      return { ...state, error: action.payload };
    case DISPLAY_NOTIFICATION:
      return { ...state, notification: action.payload };
    case SET_CONFIGURATION:
      return { ...state, websiteConfiguration: action.payload };
    case NO_CONFIGURATION:
      return { ...state, noConfiguration: true };
    case TOGGLE_MOBILE_MENU:
      return { ...state, mobileMenuIsOpen: !state.mobileMenuIsOpen };
    case SET_INSTANCE:
      return { ...state, instance: action.payload };
    case SET_CURRENT_PERSONID:
      return { ...state, currentPersonId: action.payload };
    case SET_CONSENTS:
      return { ...state, consents: action.payload };
    default:
      return state;
  }
};

export default mainReducer;
