import { IPageProperties } from "ffbad-components";
import { IWebsiteConfig } from "core/interfaces.config";
import MasterService from "services/master.service";

class ConfigurationService {
  private master: MasterService;

  constructor() {
    this.master = new MasterService();
  }

  getWebsiteConfiguration = (): {
    promise: Promise<IWebsiteConfig>;
    cancel: any;
  } => {
    const { hostname } = document.location;
    return this.master.get<IWebsiteConfig>(
      `websites/${hostname}?key=domainName`
    );
  };

  findPageById = (
    pageId: string
  ): {
    promise: Promise<IPageProperties>;
    cancel: any;
  } => {
    return this.master.get<IPageProperties>(`pages/${pageId}`);
  };
}

export default ConfigurationService;
