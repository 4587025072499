import React, {
  useRef,
  useEffect,
  useState,
  ReactNode,
  useCallback,
} from "react";
import { useParams, useHistory } from "react-router-dom";
import { PageBuilder, ComponentTypes } from "ffbad-components";
import { buildDefaultComponentsPage } from "utils/dom/dom.utils";
import { ROUTE_PATHS, ROUTE_KEYS } from "utils/routes/routes.utils";
import { setComponentsProps } from "pages/Home/components.config";
import { useMainState } from "hooks";

interface IProps {}

const DisplayNews: React.FC<IProps> = (props: IProps) => {
  const pageBuilder = useRef(new PageBuilder());
  const [content, setContent] = useState<ReactNode[]>([]);
  const { id } = useParams();
  const history = useHistory();
  const { websiteConfiguration } = useMainState();

  const onBack = useCallback(() => {
    const route = ROUTE_PATHS[ROUTE_KEYS.ALL_NEWS];
    history.push(route);
  }, [history]);

  useEffect(() => {
    let page = buildDefaultComponentsPage([
      {
        id: "display-news",
        type: ComponentTypes.DisplayNews,
        props: { aNewsId: id, onBack },
      },
    ]);
    page = setComponentsProps(page, { history });
    pageBuilder.current.init({
      ...page,
      menuSide: websiteConfiguration?.configuration.menu.side,
    });
    const tempContent = pageBuilder.current.build();
    setContent(tempContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, onBack, history]);

  return <>{content}</>;
};

export default DisplayNews;
