import {
  IPageProperties,
  ComponentTypes,
  IPageComponentProperties,
} from "ffbad-components";
import PoonaService from "services/poona/poona.service";
import CryptoService from "services/crypto/crypto.service";

import { ROUTE_PATHS, ROUTE_KEYS, buildRoute } from "utils/routes/routes.utils";
import { IInstance } from "services/poona/poona.interfaces";
const poonaService = new PoonaService();
const cryptoService = new CryptoService();

const { REACT_APP_RENEWAL_URL = "" } = process.env;

export const setComponentsProps = (
  page: IPageProperties,
  options: { history: { push: (route: string) => void }; instance?: IInstance }
): IPageProperties => {
  const { history, instance } = options;
  const args = {
    [ComponentTypes.News]: {
      onShowAllNews: () => {
        history.push(ROUTE_PATHS[ROUTE_KEYS.ALL_NEWS]);
      },
      onOpenNews: (id: string) => {
        const route = buildRoute(ROUTE_KEYS.DISPLAY_NEWS, { id });
        history.push(route);
      },
    },
    [ComponentTypes.CarouselCalendar]: {
      onClick: (id: string) => {
        const route = buildRoute(ROUTE_KEYS.DISPLAY_NEWS, { id });
        history.push(route);
      },
    },
    [ComponentTypes.Header]: {
      onMenuItemClick: (href: string) => history.push(href),
    },
    [ComponentTypes.LeftMenu]: {
      onMenuItemClick: (href: string) => history.push(href),
    },
    [ComponentTypes.Footer]: {
      onMenuItemClick: (id: string) => {
        const route = buildRoute(ROUTE_KEYS.DISPLAY_NEWS, { id });
        history.push(route);
      },
    },
    [ComponentTypes.ConnectionForm]: {
      onSubmit: (credentials: any) => {
        const { promise } = poonaService.auth({
          login: credentials.licence,
          password: credentials.password,
        });
        promise
          .then(() => {
            const key = cryptoService.encrypt(credentials.licence);
            window.location.href = `${REACT_APP_RENEWAL_URL}${instance?.initials}/${key}`;
          })
          .catch((error: Error) => {
            console.log("SignIn Error", error.message);
          });
        return promise;
      },
    },
  };

  page?.components?.forEach((component) => setComponentProps(component, args));
  return page;
};

const setComponentProps = (
  component: IPageComponentProperties,
  args: { [x: number]: any }
) => {
  component.props = {
    ...component.props,
    ...(args[component.type] || {}),
  };
};
