import React, { useEffect, useState, ReactNode, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { PageBuilder } from "ffbad-components";

import { NO_CONFIGURATION } from "core/redux/reducer/main.reducer";
import { IWebsiteConfig } from "core/interfaces.config";
import { ErrorTypes } from "core/enums/errorTypes";
import { useInstance, useWebsiteConfiguration } from "hooks";
import {
  flattenMenuItems,
  ROUTE_KEYS,
  buildRoute,
} from "utils/routes/routes.utils";
import ConfigurationService from "services/configuration";
import { setComponentsProps } from "./components.config";
import { withLayout } from "components";

import "./styles.scss";
import "ffbad-components/build/assets/css/bundle.css";

const Home: React.FC = () => {
  const service = useRef(new ConfigurationService());
  const pageBuilder = useRef(new PageBuilder());
  const [content, setContent] = useState<ReactNode[]>([]);
  const websiteConfiguration = useWebsiteConfiguration();
  const history = useHistory();
  const dispatch = useDispatch();
  const instance = useInstance();

  useEffect(() => {
    const menuItem = findPathByHref(websiteConfiguration);

    if (!menuItem) {
      // TODO: redirect to /erreur?
      return;
    }

    if (!websiteConfiguration.enable) {
      dispatch({ type: NO_CONFIGURATION });
    }

    const { promise, cancel } = service.current.findPageById(menuItem.pageId);

    promise
      .then((page) => {
        page = setComponentsProps(page, { history, instance });

        pageBuilder.current.init({
          ...page,
          menuSide: websiteConfiguration?.configuration.menu.side,
        });
        const tempContent = pageBuilder.current.build();

        setContent(tempContent);
      })
      .catch(() => {
        history.push(
          buildRoute(ROUTE_KEYS.ERROR, {
            type: ErrorTypes.PageNotFound,
          })
        );
      });
    return () => cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websiteConfiguration, history, instance]);

  return <>{content}</>;
};

export default withLayout(Home);

const findPathByHref = (websiteConfiguration: IWebsiteConfig) => {
  const { pathname } = document.location;
  const {
    configuration: {
      menu: { items = [] },
    },
  } = websiteConfiguration;

  return flattenMenuItems(items).find((item) => item.url === pathname);
};
