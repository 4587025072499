import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { buildClassName } from "utils/dom/dom.utils";

import "./styles.scss";

interface IProps {
  children?: any;
  hideContent?: boolean;
  fullscreen?: boolean;
  textAlign?: "left" | "center" | "right";
  size?: "sm" | "md" | "lg";
}

const Loading: React.FC<IProps> = (props: IProps) => {
  const {
    children = "Chargement en cours...",
    hideContent = false,
    fullscreen = false,
    textAlign = "left",
    size = "md",
  } = props;

  const className = buildClassName("loading-container", [
    [textAlign, `loading-${textAlign}`],
    [size, `loading-${size}`],
    [fullscreen, `loading-fullscreen`],
  ]);

  return (
    <div className={className}>
      <FontAwesomeIcon icon={faSpinner as any} pulse />
      &nbsp;
      {!hideContent && children}
    </div>
  );
};

export default Loading;
