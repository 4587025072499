import stopPromise from "utils/stop-promise";

const DEFAULT_ERROR_MESSAGE = "Une erreur est survenue.";
enum HTTP_STATUS {
  SUCCESS = 200,
  INTERNAL_SERVER_ERROR = 500,
}

const getEnv = (key: any) =>
  ((window as any)._env && (window as any)._env[`REACT_APP_${key}`]) ||
  process.env[`REACT_APP_${key}`] ||
  "";

class MasterService {
  private apiURL: string;

  constructor() {
    this.apiURL = getEnv("API_URL");
  }

  get = <T>(
    url: string,
    baseRequestInit?: RequestInit
  ): {
    promise: Promise<T>;
    cancel: () => void;
  } => {
    const finalUrl = `${this.apiURL}${url}`;

    return stopPromise(
      fetch(finalUrl, {
        ...baseRequestInit,
        method: "GET",
      }).then(async (response) => {
        await this.checkStatus(response);
        return await response.json();
      })
    );
  };

  post = <T>(
    url: string,
    body: any = {},
    baseRequestInit?: RequestInit,
    isFormData?: boolean
  ): {
    promise: Promise<T>;
    cancel: () => void;
  } => {
    const finalUrl = `${this.apiURL}${url}`;
    const init: RequestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: isFormData ? body : JSON.stringify(body),
      ...baseRequestInit,
    };

    return stopPromise(
      fetch(finalUrl, init).then(async (response) => {
        await this.checkStatus(response);
        return await response.json();
      })
    );
  };

  postFormData = <T>(
    url: string,
    body: FormData,
    baseRequestInit?: RequestInit
  ): {
    promise: Promise<T>;
    cancel: () => void;
  } => {
    const finalUrl = `${this.apiURL}${url}`;
    const init: RequestInit = {
      ...baseRequestInit,
      method: "POST",
      body,
    };

    return stopPromise(
      fetch(finalUrl, init).then(async (response) => {
        await this.checkStatus(response);
        return await response.json();
      })
    );
  };

  private checkStatus = async (response: Response) => {
    if (response.status === HTTP_STATUS.SUCCESS) {
      return;
    }

    const { error = DEFAULT_ERROR_MESSAGE } = await response.json();
    throw new Error(error);
  };
}

export default MasterService;
