import React, { useState, useRef, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import { formatOfFilenameMatches } from "utils/file/file.utils";
import { KeyCodes } from "core/enums/keycodes";

import "./index.scss";

interface IProps {
  onUpload: (file: any) => void;
  availableFileFormats?: string[];
  defaultFile?: any;
}

const fileSizeMaxAllowed = 2;

const ERRORS = {
  NO_FILE: "Aucun fichier n'a pu être importé",
  JUST_ONE_FILE: "Vous ne pouvez importer qu'un seul fichier",
  FILE_FORMAT: "Le format de fichier est incorrect",
  WEIGTH: `Le fichier est trop lourd (taille maximale : ${fileSizeMaxAllowed}Mo)`,
};

// Documentation: https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop
const DropZone: React.FC<IProps> = (props: IProps) => {
  const { onUpload, availableFileFormats = [], defaultFile } = props;
  const [isOver, setIsOver] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [currentFile, setCurrentFile] = useState<any>();
  const fileInputRef = useRef<any>();

  useEffect(() => {
    if (!currentFile && defaultFile) {
      setCurrentFile(defaultFile);
    }
  }, [currentFile, defaultFile]);

  const handleOnDrop = (e: any) => {
    e.preventDefault();
    setIsOver(false);
    setErrorMessage("");

    if (!dataWasProvided(e)) {
      setErrorMessage(ERRORS.NO_FILE);
      return;
    }

    if (!dataContainsOneFile(e)) {
      setErrorMessage(ERRORS.JUST_ONE_FILE);
      return;
    }

    const file = extractFirstFileFromEvent(e);
    checkAndUpdateCurrentFile(file);
  };

  const handleOnDragOver = (e: any) => {
    e.preventDefault();
    setIsOver(true);
  };

  const handleOnDragLeave = (e: any) => {
    e.preventDefault();
    setIsOver(false);
  };

  const handleOnSuppressFile = (e: any = null) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    onUpload(null);
    setCurrentFile(null);
  };

  const handleOnClickOnDropzone = () => {
    fileInputRef.current.click();
  };

  const handleOnReceiveFileFromHiddenInput = (e: any) => {
    const { files = [] } = e.target;

    setErrorMessage("");

    if (!files.length) {
      return;
    }

    if (files.length !== 1) {
      setErrorMessage(ERRORS.JUST_ONE_FILE);
      return;
    }

    const [firstFile] = files;
    checkAndUpdateCurrentFile(firstFile);
  };

  const checkAndUpdateCurrentFile = (file: any) => {
    handleOnSuppressFile();

    if (!filenameIsValid(file.name, availableFileFormats)) {
      setErrorMessage(ERRORS.FILE_FORMAT);
      return;
    }

    const currentSize = file.size / 1e6;
    if (currentSize > fileSizeMaxAllowed) {
      setErrorMessage(ERRORS.WEIGTH);
      return;
    }

    onUpload(file);
    setCurrentFile(file);
  };

  return (
    <div className="dropzone-container">
      <input
        ref={fileInputRef}
        className="dropzone-hidden-input"
        type="file"
        onChange={handleOnReceiveFileFromHiddenInput}
      />

      <div
        className="dropzone"
        onClick={handleOnClickOnDropzone}
        onDrop={handleOnDrop}
        onDragOver={handleOnDragOver}
        onDragLeave={handleOnDragLeave}
        role="button"
        tabIndex={0}
        aria-label="dropzone"
        onKeyDown={(e) => {
          if (e.keyCode === KeyCodes.ENTER || e.keyCode === KeyCodes.SPACE) {
            e.preventDefault();
            handleOnClickOnDropzone();
          }
        }}
      >
        <div className="dropzone-content">
          {currentFile && (
            <>
              <div className="wrap-long-text">{currentFile.name}</div>
              <div
                className="link"
                role="button"
                tabIndex={0}
                title="Supprimer le fichier"
                onClick={handleOnSuppressFile}
                onKeyDown={(e) => {
                  if (
                    e.keyCode === KeyCodes.ENTER ||
                    e.keyCode === KeyCodes.SPACE
                  ) {
                    e.preventDefault();
                    e.stopPropagation();
                    handleOnSuppressFile();
                  }
                }}
              >
                Supprimer le fichier
              </div>
            </>
          )}

          {!currentFile && (
            <span className="dropzone-plus-sign">
              <FontAwesomeIcon icon={faPlus as any} />
            </span>
          )}
        </div>
      </div>

      {!errorMessage && (
        <span className="explain-text">
          {isOver && "Relâchez le bouton pour prendre en compte le fichier"}
          {!isOver && (
            <>
              <FontAwesomeIcon icon={faInfoCircle as any} />
              &nbsp;Cliquer ou déposer un fichier sur la zone bleu.
              <br />
              Format autorisés :&nbsp;
              {availableFileFormats.length !== 0 && (
                <>{availableFileFormats.join(", ")}</>
              )}
              &nbsp;-&nbsp;Taille max. : 2Mo
            </>
          )}
        </span>
      )}

      {errorMessage && <span className="dropzone-error">{errorMessage}</span>}
    </div>
  );
};

export default DropZone;

const dataContainsOneFile = (e: any) => {
  return e.dataTransfer.items.length === 1;
};

const dataWasProvided = (e: any) => {
  return (
    e &&
    e.dataTransfer &&
    e.dataTransfer.items &&
    e.dataTransfer.items.length !== 0
  );
};

const extractFirstFileFromEvent = (e: any) => {
  const { items } = e.dataTransfer;
  const [firstFile] = items;
  return firstFile.getAsFile();
};

const filenameIsValid = (
  filename: string,
  availableFileFormats?: string[]
): boolean => {
  if (!availableFileFormats || !availableFileFormats.length) {
    return true;
  }

  return formatOfFilenameMatches(filename, availableFileFormats);
};
