import React from "react";
import { useParams } from "react-router-dom";

import { ErrorTypes } from "core/enums/errorTypes";
import { FullscreenError } from "components";

const DEFAULT_ERROR_KEY = ErrorTypes.Unknown;

const Error: React.FC = () => {
  const { type = DEFAULT_ERROR_KEY } = useParams();
  return <FullscreenError type={type} />;
};

export default Error;
