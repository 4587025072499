import { createStore } from "redux";
import mainReducer from "./reducer/main.reducer";

const store = createStore(
  mainReducer,
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
