import {
  IPageProperties,
  ComponentTypes,
  IPageComponentProperties,
} from "ffbad-components";

export const scrollToTop = (elementId: string): void => {
  const element = document.getElementById(elementId);
  if (!element) {
    return;
  }

  element.scrollIntoView({ behavior: "smooth", block: "start" });
};

export const buildClassName = (
  initialClass: string,
  args: (string | boolean)[][]
): string => {
  const classNames: string[] = [initialClass];

  if (args.length) {
    args.forEach(([shouldBeAdded, name]) => {
      if (!shouldBeAdded) {
        return;
      }

      classNames.push(name as string);
    });
  }

  return classNames.join(" ");
};

let timer: NodeJS.Timeout;

/**
 * Usefull to avoid state concurrency
 * @param callback method
 * @param delay time before triggering in ms
 */
export const delayedCallback = (callback: any, delay: number = 100) => {
  if (timer) {
    clearTimeout(timer);
  }

  timer = setTimeout(() => callback(), delay);
};

export const buildDefaultComponentsPage = (
  components: IPageComponentProperties[] = [],
  args: {
    hideHeader?: boolean;
    hideFooter?: boolean;
  } = {}
): IPageProperties => {
  const { hideHeader = false, hideFooter = false } = args;
  const parentId = "top";
  const top = { id: parentId, type: ComponentTypes.TopLevel };
  const header = {
    id: "4CqCgUTdCq",
    type: ComponentTypes.Header,
    parentId,
  };
  const footer = {
    id: "rgjeogiej",
    type: ComponentTypes.Footer,
    parentId,
  };

  const page: IPageProperties = { components: [top] };

  if (!hideHeader) {
    page.components?.push(header);
  }

  components.forEach((aComponent: IPageComponentProperties) => {
    page.components?.push({ ...aComponent, parentId });
  });

  if (!hideFooter) {
    page.components?.push(footer);
  }

  return page;
};

/**
 * https://supralog.myjetbrains.com/youtrack/issue/FFBAD-814#focus=Comments-4-11393.0-0
 */
export const getPropsForInputDate = (): any => {
  return isSafari()
    ? {
        mask: "99/99/9999",
        maskChar: "_",
      }
    : { type: "date" };
};

/**
 * https://supralog.myjetbrains.com/youtrack/issue/FFBAD-814
 */
const isSafari = (): boolean => {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.includes("safari") && !userAgent.includes("chrome");
};

export const isFirefox = (): boolean => {
  const userAgent = navigator.userAgent.toLowerCase();
  return !isSafari() && userAgent.includes("firefox");
};
