import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useInstance } from "hooks";

const { REACT_APP_RENEWAL_URL = "" } = process.env;

const OldRoutes: React.FC = () => {
  const instance = useInstance();
  const { keyForLicenceRenewal } = useParams<{
    keyForLicenceRenewal?: string;
  }>();

  useEffect(() => {
    const initials =
      instance?.initials ||
      document.location.href
        .replace("http://", "")
        .replace("https://", "")
        .split(".")[0];

    const url = keyForLicenceRenewal
      ? `${REACT_APP_RENEWAL_URL}${initials}/${keyForLicenceRenewal}`
      : `${REACT_APP_RENEWAL_URL}${initials}`;

    document.location.href = url;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default OldRoutes;
