import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";

import { IType } from "core/interfaces.config";

import "./styles.scss";

interface IProps {
  children: any;
  type?: IType;
  timeInMs?: number;
  onHide: () => void;
}

const iconFromType = (type: string): any => {
  const data: any = {
    primary: faCheck,
    secondary: faCheck,
    danger: faExclamationTriangle,
  };

  return data[type];
};

const Notification: React.FC<IProps> = (props: IProps) => {
  const [visible, setVisible] = useState(true);
  const { children, type = "primary", timeInMs = 5000, onHide } = props;

  useEffect(() => {
    setTimeout(() => {
      setVisible(false);
      setTimeout(() => {
        onHide();
      }, 300);
    }, timeInMs);
  }, [onHide, timeInMs]);

  const className = `notification-container notification-${type} ${
    visible ? "active" : "hide"
  }`;

  return (
    <div className={className}>
      <FontAwesomeIcon icon={iconFromType(type)} />
      {children}
    </div>
  );
};

export default Notification;
