import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";

import { useWebsiteConfiguration } from "hooks";
import { flattenMenuItems } from "utils/routes/routes.utils";
import Home from "pages/Home";

const InternalRouting: React.FC = () => {
  const [items, setItems] = useState<any[]>([]);
  const websiteConfiguration = useWebsiteConfiguration();

  useEffect(() => {
    if (!websiteConfiguration || !websiteConfiguration.configuration) {
      return;
    }

    const filteredArray = flattenMenuItems(
      websiteConfiguration.configuration.menu.items
    ).filter((item) => item.url && item.pageId);

    setItems(filteredArray);
  }, [websiteConfiguration]);

  return (
    <>
      {items.map((item) => (
        <Route key={item.url} path={item.url} component={Home} exact />
      ))}
    </>
  );
};

export default InternalRouting;
