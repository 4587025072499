import { IRouteArgs } from "core/interfaces.config";

import { ROUTE_KEYS, ROUTE_PATHS } from "utils/routes/routes.utils";

import Home from "pages/Home";
import Config from "pages/Config";
import Error from "pages/Error";
import AllNews from "pages/AllNews";
import DisplayNews from "pages/DisplayNews";
import OldRoutes from "pages/OldRoutes";

const routes: IRouteArgs[] = [
  {
    key: ROUTE_KEYS.HOME,
    path: ROUTE_PATHS[ROUTE_KEYS.HOME],
    component: Home,
    exact: true,
  },
  {
    key: ROUTE_KEYS.ALL_NEWS,
    path: ROUTE_PATHS[ROUTE_KEYS.ALL_NEWS],
    component: AllNews,
    exact: true,
  },
  {
    key: ROUTE_KEYS.DISPLAY_NEWS,
    path: ROUTE_PATHS[ROUTE_KEYS.DISPLAY_NEWS],
    component: DisplayNews,
    exact: true,
  },
  {
    key: ROUTE_KEYS.ERROR,
    path: ROUTE_PATHS[ROUTE_KEYS.ERROR],
    component: Error,
    exact: true,
  },
  // Old routes - redirections.
  {
    key: ROUTE_KEYS.OLD_RENEWAL_REGISTER,
    path: ROUTE_PATHS[ROUTE_KEYS.OLD_RENEWAL_REGISTER],
    component: OldRoutes,
    exact: true,
  },
  {
    key: ROUTE_KEYS.OLD_RENEWAL,
    path: ROUTE_PATHS[ROUTE_KEYS.OLD_RENEWAL],
    component: OldRoutes,
    exact: true,
  },
  // CONFIG
  {
    key: ROUTE_KEYS.CONFIG,
    path: ROUTE_PATHS[ROUTE_KEYS.CONFIG],
    component: Config,
    exact: true,
  },
];

export default routes;
