import store from "core/redux";
import MasterService from "services/master.service";
import { IInstance } from "./poona.interfaces";

class PoonaService {
  private master: MasterService;

  constructor() {
    this.master = new MasterService();
  }

  get defaultHeaders(): RequestInit {
    const { choosenSeasonId, currentPersonId } = store.getState();
    return {
      headers: {
        apiSeasonId: choosenSeasonId,
        currentPersonId,
      },
    } as RequestInit;
  }

  instance = (
    instanceId: string | number
  ): { promise: Promise<IInstance>; cancel: any } => {
    const instanceFromLocalStorage = JSON.parse(
      localStorage.getItem("instance") || "{}"
    ) as IInstance;

    if (instanceFromLocalStorage.instanceId === instanceId) {
      return {
        promise: Promise.resolve(instanceFromLocalStorage),
        cancel: () => {},
      };
    }

    return this.master.get<IInstance>(
      `poona/instance/${instanceId}/full`,
      this.defaultHeaders
    );
  };

  auth = (
    body: any = {}
  ): {
    promise: Promise<{
      accessToken: string;
    }>;
    cancel: () => void;
  } => {
    return this.master.post("auth/poona", body);
  };
}

export default PoonaService;
