const CryptoJS = require("crypto-js");
const SECRET = "M'E=x#`Vfv+Z3Mv<";

class LocalStorageService {
  public set = (key: string, value: any) => {
    const valueToSave =
      typeof value === "string" ? value : JSON.stringify(value);
    const encryptedValue = this.encrypt(valueToSave);
    localStorage.setItem(key, encryptedValue);
  };

  public get = (key: any): string | undefined => {
    const item = localStorage.getItem(key);
    return item ? this.decrypt(item) : undefined;
  };

  public remove = (key: string) => {
    localStorage.removeItem(key);
  };

  private encrypt = (content: string): string => {
    return CryptoJS.AES.encrypt(content, SECRET)
      .toString()
      .replace(/=/gi, "___");
  };

  private decrypt = (crypted: string): string => {
    return CryptoJS.AES.decrypt(crypted.replace(/___/gi, "="), SECRET).toString(
      CryptoJS.enc.Utf8
    );
  };
}

export default LocalStorageService;
