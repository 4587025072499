import { IWebsiteConfig } from "core/interfaces.config";

export const ROUTE_KEYS = {
  HOME: "home",
  ALL_NEWS: "all-news",
  DISPLAY_NEWS: "display_news",
  CONFIG: "config",
  ERROR: "error",
  CONNECTION_FORM: "connection_form",

  // Old routes - redirections.
  OLD_RENEWAL_REGISTER: "old_renewal_register",
  OLD_RENEWAL: "old_renewal",
};

export const ROUTE_PATHS = {
  [ROUTE_KEYS.HOME]: "/",
  [ROUTE_KEYS.ALL_NEWS]: "/actualites",
  [ROUTE_KEYS.DISPLAY_NEWS]: "/actualite/:id",
  [ROUTE_KEYS.CONFIG]: "/private-config-checker",
  [ROUTE_KEYS.ERROR]: "/erreur/:type?",
  [ROUTE_KEYS.CONNECTION_FORM]: "/connexion-form",

  // Old routes - redirections.
  [ROUTE_KEYS.OLD_RENEWAL_REGISTER]: "/inscription",
  [ROUTE_KEYS.OLD_RENEWAL]: "/renouvellement-cotisation/:keyForLicenceRenewal",
};

/**
 * Build a complete route with argument(s).
 * @param routeKey Unique key to identify the route (from ROUTE_KEYS constant).
 * @param args All arguments that'll replace arguments from route path.
 * @returns A fully fonctionnal route path.
 */
export const buildRoute = (routeKey: string, args: any = {}): string => {
  let routePath = ROUTE_PATHS[routeKey] || "";

  const keys = Object.keys(args);
  for (const key of keys) {
    const value: string = args[key];
    routePath = routePath.replace(`:${key}?`, value).replace(`:${key}`, value);
  }

  return routePath;
};

export const flattenMenuItems = (items: any[]) => {
  return items.reduce((prev: any[], curr: any) => {
    const temp: any[] = [...prev, curr];
    if (curr.menu && curr.menu.length) {
      curr.menu.forEach((item: any) => temp.push(item));
    }
    return temp;
  }, []);
};

export const fetchDomainToRedirect = (
  website: IWebsiteConfig
): string | undefined => {
  const { REACT_APP_EC_DOMAIN = "" } = process.env;
  const currentDomain = window.location.host;

  if (!website || !currentDomain.includes(REACT_APP_EC_DOMAIN)) {
    return;
  }

  const { domainNames = [] } = website;
  const domainToRedirect = domainNames.find(
    (aDomain) =>
      !aDomain.includes("localhost") && !aDomain.includes(REACT_APP_EC_DOMAIN)
  );

  if (!domainToRedirect) {
    return;
  }

  const url = window.location.href
    .replace(/http?(s):\/\//gi, "")
    .split("/")
    .splice(3, 10)
    .join("/");

  return `https://${domainToRedirect}/${url}`;
};
