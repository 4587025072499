import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import { IRouteArgs, IWebsiteConfig } from "core/interfaces.config";
import {
  SET_CONFIGURATION,
  SET_INSTANCE,
  NO_CONFIGURATION,
} from "core/redux/reducer/main.reducer";
import { useWebsiteConfiguration } from "hooks";
import ConfigurationService from "services/configuration";
import PoonaService from "services/poona/poona.service";
import LocalStorageService from "services/localStorage/localStorage.service";
import routes from "./routes.config";
import { updateTheme } from "utils/theme/theme.utils";
import { fetchDomainToRedirect } from "utils/routes/routes.utils";
import { Loading } from "components";
import InternalRouting from "./components/InternalRouting";

const service = new ConfigurationService();
const poonaService = new PoonaService();
const localStorageService = new LocalStorageService();

const Router: React.FC = () => {
  const dispatch = useDispatch();
  const websiteConfiguration = useWebsiteConfiguration();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const { promise, cancel } = service.getWebsiteConfiguration();

    promise
      .then((payload: IWebsiteConfig) => {
        const domainToRedirect = fetchDomainToRedirect(payload);

        if (domainToRedirect) {
          window.location.href = domainToRedirect;
          return;
        }

        updateTheme(payload);
        dispatch({ type: SET_CONFIGURATION, payload });
        localStorageService.set("iid", payload.ownerId);
        localStorageService.set("wid", payload.id);
      })
      .catch(() => {
        dispatch({ type: NO_CONFIGURATION });
        setLoading(false);
      });
    return () => cancel();
  }, [dispatch]);

  useEffect(() => {
    if (!websiteConfiguration.ownerId) {
      return;
    }

    const { promise, cancel } = poonaService.instance(
      websiteConfiguration.ownerId
    );

    promise
      .then((payload) => {
        dispatch({ type: SET_INSTANCE, payload });
        setLoading(false);
      })
      .catch((err) => console.error("Router:", err));
    return () => cancel();
  }, [websiteConfiguration, dispatch]);

  if (loading) {
    return <Loading fullscreen />;
  }

  return (
    <BrowserRouter>
      <Switch>
        {routes.map((route: IRouteArgs) => (
          <Route {...route} />
        ))}

        {/* WEBSITE CONFIGURATION ROUTING */}
        <InternalRouting />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
