import { IWebsiteConfig } from "core/interfaces.config";

export const updateTheme = (config: IWebsiteConfig) => {
  const documentStyle = document.documentElement.style;
  const {
    configuration: {
      colors: { primary, secondary, text },
    },
  } = config;

  documentStyle.setProperty("--color-primary", primary);
  documentStyle.setProperty("--color-secondary", secondary);
  documentStyle.setProperty("--color-text", text);
};
