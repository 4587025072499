import React, {
  useRef,
  useEffect,
  useState,
  ReactNode,
  useCallback,
} from "react";
import { useParams, useHistory } from "react-router-dom";
import { PageBuilder, ComponentTypes } from "ffbad-components";

import { buildDefaultComponentsPage } from "utils/dom/dom.utils";
import { buildRoute, ROUTE_KEYS } from "utils/routes/routes.utils";
import { setComponentsProps } from "pages/Home/components.config";
import { useMainState } from "hooks";

const AllNews: React.FC = () => {
  const pageBuilder = useRef(new PageBuilder());
  const [content, setContent] = useState<ReactNode[]>([]);
  const { id } = useParams<{ id: string }>();
  const { websiteConfiguration } = useMainState();
  const history = useHistory();

  const handleOnOpen = useCallback(
    (id: string) => {
      const route = buildRoute(ROUTE_KEYS.DISPLAY_NEWS, { id });
      history.push(route);
    },
    [history]
  );

  const handleOnError = useCallback(() => {
    // TODO: ?
  }, []);

  useEffect(() => {
    let page = buildDefaultComponentsPage([
      {
        id: "display-news",
        type: ComponentTypes.DisplayAllNews,
        props: {
          onOpen: handleOnOpen,
          onError: handleOnError,
        },
      },
    ]);
    page = setComponentsProps(page, { history });
    pageBuilder.current.init({
      ...page,
      menuSide: websiteConfiguration?.configuration.menu.side,
    });
    const tempContent = pageBuilder.current.build();
    setContent(tempContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, handleOnOpen, handleOnError, history]);

  return <>{content}</>;
};

export default AllNews;
