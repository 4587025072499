import React from "react";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";

import { DISPLAY_NOTIFICATION } from "core/redux/reducer/main.reducer";

import Notification from "../../components/Notification";
import { useInstance, useMainState } from "hooks";
import { FullscreenError } from "components";
import { ErrorTypes } from "core/enums/errorTypes";

const withLayout = (Component: React.FC<any>) => () => {
  const dispatch = useDispatch();
  const { notification, noConfiguration } = useMainState();
  const instance = useInstance();

  if (noConfiguration) {
    return (
      <FullscreenError
        type={ErrorTypes.NoWebsiteConfiguration}
        hideGoToHomeLink
      />
    );
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{instance?.name || "FFBaD"} - Club de Badminton</title>
      </Helmet>

      {notification && (
        <Notification
          type={notification.type || "primary"}
          onHide={() => {
            dispatch({ type: DISPLAY_NOTIFICATION, payload: undefined });
          }}
        >
          {notification.message}
        </Notification>
      )}

      <Component />
    </>
  );
};

export default withLayout;
